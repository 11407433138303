import React, { useEffect, useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import "./main.css";
import { useDispatch, useSelector } from 'react-redux';
import { get_all_blogs } from '../redux/authslice';
import { useNavigate } from 'react-router-dom';


const MainBlog = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const main_blog_data = useSelector((state) => state?.counter?.main_blog_data)
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const planSectionRef = useRef(null);
    const contactref = useRef(null);
    const cardContentRef = useRef(null);
    const [autoScrollInterval, setAutoScrollInterval] = useState(null);

    function cleanContent(content) {
        return content
            ?.replace(/\*\*/g, "")
            ?.replace(/\*/g, "")
            ?.replace(/:/g, "")
            ?.replace(/###/g, "\n\n\n")
            ?.replace(/title/gi, "")
            ?.split("\n")
            ?.map((line) => line.trim())
            ?.filter((line) => line.length > 0)
            ?.join("\n");
    }

    const handleSlide = () => {
        planSectionRef.current?.scrollIntoView({ behavior: 'smooth' });

    };

    const handleSlide2 = () => {
        contactref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const openPopup = () => {
        setIsPopupVisible(true);
    };

    const closePopup = () => {
        setIsPopupVisible(false);
    };

    const handleContentClick = (e) => {
        e.stopPropagation();
    };

    const handleScrollNext = () => {
        const cards = cardContentRef.current;
        if (cards) {
            cards.scrollLeft +=
                window.innerWidth / 2 > 600
                    ? window.innerWidth / 2
                    : window.innerWidth - 100;
        }
    };

    const handleScrollPrev = () => {
        const cards = cardContentRef.current;
        if (cards) {
            cards.scrollLeft -=
                window.innerWidth / 2 > 600
                    ? window.innerWidth / 2
                    : window.innerWidth - 100;
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            handleScrollNext();
        }, 5000);
        setAutoScrollInterval(interval);
        return () => {
            if (autoScrollInterval) {
                clearInterval(autoScrollInterval);
            }
        };
    }, []);

    const fltbtn = {
        background: main_blog_data?.[0]?.floating_buttons?.[0]?.bg_color,
        fontSize: main_blog_data?.[0]?.floating_buttons?.[0]?.font_size,
        color: main_blog_data?.[0]?.floating_buttons?.[0]?.text_color,
    }



    useEffect(() => {
        const dmn = localStorage.getItem("domain") || window.location.origin.split("//")[1];
        if (dmn) {
            dispatch(get_all_blogs(dmn, navigate, dispatch))
        }
    }, [])

    return (
        <>
            <section className="header-custom-main-blog-mm">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="cus-flex d-flex justify-content-between align-items-center flex-wrap">
                                <div className="cus-flex-1">
                                    <a href="" className="SNEAKERRESTORATION text-uppercase">
                                        {main_blog_data?.[0]?.posts?.[0]?.title}
                                    </a>
                                </div>
                                <div className="cus-flex-2" style={{ cursor: "pointer" }} >
                                    <a className="CONTACTUS-main-blog" onClick={handleSlide2}>
                                        CONTACT US
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="banner-custom-main-blog"
                style={{
                    backgroundImage: `url(${main_blog_data?.[0]?.hero_image_url || main_blog_data?.[0]?.posts?.[0]?.image_url})`,
                    backgroundPosition: "center",
                    padding: "100px 0",
                }}>
                <span className='mask-main-blog'></span>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="cus-banner-main-blog">
                                <p className="blog-p-main-blog text-uppercase">{main_blog_data?.[0]?.posts?.[0]?.title}</p>
                                <p className="blog-p1-main-blog">
                                    {main_blog_data?.[0]?.posts?.[0]?.content?.match(/<\/?[a-z][\s\S]*>/i) ? (
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: cleanContent(main_blog_data?.[0]?.posts?.[0]?.content)?.slice(0, 190),
                                            }}
                                        />
                                    ) : (
                                        `${cleanContent(main_blog_data?.[0]?.posts?.[0]?.content)?.slice(0, 190)}...`
                                    )}
                                </p>

                                <a className="read-more-main-blog" style={{ cursor: "pointer" }} onClick={handleSlide}>
                                    Read More
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="Latest-Posts-main-blog">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="Latest-Posts-heading-main-blog">
                                <p className="Latest-Posts-p-main-blog">Latest-Posts</p>
                                <p className="Latest-Posts-p1-main-blog">
                                    See what people from all around the world are saying about our
                                    website.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="outer-div-main-blog" ref={planSectionRef}>
                        {main_blog_data?.[0]?.posts?.slice(0, 5).map((post) => (
                            <div key={post.id} className="post repeat-div-main-blog">
                                <div className="left-90-main-blog">
                                    <img
                                        src={post?.image_url}
                                        alt={post?.title}
                                        style={{ width: "100%", borderRadius: "8px", marginBottom: "10px" }}
                                    />
                                </div>
                                <div className="flex-right-90-main-blog">
                                    <h2 className="h2-1-main-blog">{post.title}</h2>
                                    <p className="p-cus-main-blog">
                                        {/<\/?[a-z][\s\S]*>/i.test(post?.content) ? (
                                            <span dangerouslySetInnerHTML={{ __html: cleanContent(post?.content)?.slice(0, 700) + '...' }} />
                                        ) : (
                                            cleanContent(post?.content)?.slice(0, 700) + '...'
                                        )}
                                    </p>
                                    <a
                                        className="a-tag-main-blog"
                                        href={`/blog/${post.id}`}
                                        target='_blank'
                                        rel="noopener noreferrer"
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        Read More
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section className="get-update-main-blog">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="button-container-main-blog" onClick={openPopup}>
                            {main_blog_data?.[0]?.floating_buttons?.length > 0 ? (<div className="floating-button-main-blog" style={fltbtn} >
                                {main_blog_data?.[0]?.floating_buttons?.[0]?.text ? main_blog_data?.[0]?.floating_buttons?.[0]?.text : "Our Blog"}
                            </div>) : (<div className="floating-button-main-blog" style={fltbtn} >
                                Our Blog
                            </div>)}

                        </div>
                        <div>
                            {isPopupVisible && (
                                <div id="popup" className="popup-overlay">
                                    <div className="popup-content" onClick={handleContentClick}>
                                        <button className="close-btn" onClick={closePopup}>
                                            ×
                                        </button>
                                        <section>
                                            <div className="container col-lg-12">
                                                <div className="main-section p-3">
                                                    <div className="text-center mb-3">
                                                        <h3>Latest Blog Posts</h3>
                                                    </div>
                                                    <div
                                                        id="carouselExample"
                                                        className="carousel slide"
                                                        data-bs-ride="carousel"
                                                        data-bs-interval="3000"
                                                    >
                                                        <div className="carousel-indicators">
                                                            {main_blog_data?.[0]?.posts?.map((_, idx) => (
                                                                <button
                                                                    key={idx}
                                                                    type="button"
                                                                    data-bs-target="#carouselExample"
                                                                    data-bs-slide-to={idx}
                                                                    className={idx === 0 ? "active" : ""}
                                                                    aria-current={idx === 0 ? "true" : ""}
                                                                    aria-label={`Slide ${idx + 1}`}
                                                                />
                                                            ))}
                                                        </div>
                                                        <div className="carousel-inner">
                                                            {main_blog_data?.[0]?.posts?.map((ele, idx) => (
                                                                <div
                                                                    key={idx}
                                                                    className={`carousel-item ${idx === 0 ? "active" : ""}`}
                                                                >
                                                                    <div className="d-flex justify-content-center">
                                                                        <img
                                                                            className="w-100 img-fluid"
                                                                            src={ele?.image_url || "http://35.154.171.148:8013/media/sneaker_restoration_1731647717.jpg"}
                                                                            alt={ele?.title || "Default Alt Text"}
                                                                            style={{ maxHeight: "285px", objectFit: "cover" }}
                                                                        />
                                                                    </div>
                                                                    <div className="text-center my-3">
                                                                        <h5 className="text-dark">{ele?.title || "Default Title"}</h5>
                                                                        <p className="text-dark">
                                                                            {ele?.content?.match(/<\/?[a-z][\s\S]*>/i) ? (
                                                                                <span
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html: cleanContent(ele?.content)?.slice(0, 200),
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                ele?.content?.slice(0, 200) || "Default description for the blog post."
                                                                            )}
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <button
                                                            className="carousel-control-prev"
                                                            type="button"
                                                            data-bs-target="#carouselExample"
                                                            data-bs-slide="prev"
                                                        >
                                                            <span className="carousel-control-prev-icon-main-blog" aria-hidden="true" />
                                                            <span className="visually-hidden"><i class="fa-solid fa-chevron-left"></i></span>
                                                            <i class="fa-solid fa-chevron-left"></i>
                                                        </button>
                                                        <button
                                                            className="carousel-control-next"
                                                            type="button"
                                                            data-bs-target="#carouselExample"
                                                            data-bs-slide="next"
                                                        >
                                                            <span className="carousel-control-next-icon-main-blog" aria-hidden="true" />
                                                            <span className="visually-hidden">Next</span>
                                                            <i class="fa-solid fa-chevron-right"></i>

                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>


                            )}
                        </div>
                    </div>
                </div>
            </section>

            <div className="slider-main-page-main-blog">
                <button className="prev-main-page-main-blog" onClick={handleScrollPrev}>
                    &#9664;
                </button>

                <div className="card-content-main-blog" ref={cardContentRef}>
                    {main_blog_data?.[0]?.posts?.map((ele, idx) => (
                        <div className="card-main-page-main-blog" key={idx} >
                            <a
                                className="text-decoration-none"
                                href={`/blog/${ele?.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(e) => e.stopPropagation()}
                            >
                                <div className="card-img-main-page-main-blog">
                                    <img
                                        src={ele?.image_url}
                                        alt={ele?.title || "Default Alt Text"}
                                    />
                                </div>
                                <div className="card-text-main-page-main-blog">
                                    <h2>{ele?.title || "Default Title"}</h2>
                                    <p>
                                        {ele?.content?.match(/<\/?[a-z][\s\S]*>/i) ? (
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: cleanContent(ele?.content)?.slice(0, 100),
                                                }}
                                            />
                                        ) : (
                                            cleanContent(ele?.content?.slice(0, 100) || "Default description for the blog post.") + "..."
                                        )}
                                    </p>


                                </div>
                            </a>
                        </div>
                    ))}
                </div>

                <button className="next-main-page-main-blog" onClick={handleScrollNext}>
                    &#9654;
                </button>
            </div>

            {/* ============Footer section ================= */}
            <section className="footer bg-dark text-light py-5" ref={contactref}>
                <div className="container">
                    <div className="row">

                        <div className="col-lg-4 mb-4">
                            <h5 className="text-uppercase">About Us</h5>

                            <ul className="list-unstyled">
                                {main_blog_data?.[0]?.business_details?.business_address ? <li>
                                    <i className="fas fa-map-marker-alt" /> {main_blog_data?.[0]?.business_details?.business_address}
                                </li> : ""}
                                {main_blog_data?.[0]?.business_details?.business_phone ? <li>
                                    <i className="fas fa-phone-alt" /> {main_blog_data?.[0]?.business_details?.business_phone}
                                </li> : ""}
                                {main_blog_data?.[0]?.business_details?.business_phone ?
                                    <li>
                                        <i className="fas fa-envelope" /> {main_blog_data?.[0]?.business_details?.business_email}
                                    </li> : ""}
                            </ul>
                        </div>
                        {/* Quick Links Section */}
                        <div className="col-lg-4 mb-4">
                            <h5 className="text-uppercase">

                            </h5>
                        </div>
                        {/* Newsletter Section */}
                        <div className="col-lg-4 mb-4">
                            <h5 className="text-uppercase">Newsletter</h5>
                            <p>Subscribe to our newsletter for the latest updates.</p>
                            <form>
                                <div className="right-footer mb-2">
                                    <i className="fa-solid fa-envelope" />
                                    <input
                                        type="text"
                                        placeholder="Your Email"
                                        className="your-email"
                                    />
                                </div>
                                <button type="submit" className="subscribe-btn w-100">
                                    Subscribe
                                </button>
                            </form>
                        </div>
                    </div>
                    {/* Footer Bottom */}
                    <div className="row mt-4">
                        <div className="col text-center">
                            <p className="mb-0">© 2024 Your Company. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};



export default MainBlog;
