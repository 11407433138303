import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { get_all_blogs } from '../../redux/authslice';
import { toast } from 'react-toastify';

const BlogWebsite = () => {
    const dispatch = useDispatch();
    const main_blog_data = useSelector((state) => state?.counter?.main_blog_data)
    const cardContentRef = useRef(null);
    const [text, setText] = useState("Our Blog");
    const [bg_color, setbg_color] = useState("#007bff");
    const [text_color, settext_color] = useState("#ffffff");
    const [fontSize, setfontSize] = useState("16px");
    const [autoScrollInterval, setAutoScrollInterval] = useState(null);

    function cleanContent(content) {
        return content
            ?.replace(/\*\*/g, "")
            ?.replace(/\*/g, "")
            ?.replace(/:/g, "")
            ?.replace(/###/g, "\n\n\n")
            ?.replace(/title/gi, "")
            ?.split("\n")
            ?.map((line) => line.trim())
            ?.filter((line) => line.length > 0)
            ?.join("\n");
    }

    useEffect(() => {
        let dmn = localStorage.getItem("carousal");
        if (dmn) {
            dispatch(get_all_blogs(dmn, dispatch));
        }
    }, []);


    useEffect(() => {
        const interval = setInterval(() => {
            handleScrollNext();
        }, 5000);

        setAutoScrollInterval(interval);
        return () => {
            if (autoScrollInterval) {
                clearInterval(autoScrollInterval);
            }
        };
    }, []);


    const copyToClipboard = (code) => {
        navigator.clipboard.writeText(code);
        toast.info("Code copied to clipboard!");
    };



    const handleScrollNext = () => {
        const cards = cardContentRef.current;
        if (cards) {
            cards.scrollLeft +=
                window.innerWidth / 2 > 600
                    ? window.innerWidth / 2
                    : window.innerWidth - 100;
        }
    };

    const handleScrollPrev = () => {
        const cards = cardContentRef.current;
        if (cards) {
            cards.scrollLeft -=
                window.innerWidth / 2 > 600
                    ? window.innerWidth / 2
                    : window.innerWidth - 100;
        }
    };


    const htmlCode = ` <div id="hiddenData" style="display: none;"></div>

<div style="display: flex; align-items: center; overflow: hidden; width: 80%; max-width: 1200px; position: relative; margin: auto;">
    <button onclick="scrollPrev()" style="font-size: 24px; color: #333; border: none; border-radius: 50%; width: 40px; height: 40px; cursor: pointer; display: flex; justify-content: center; align-items: center; z-index: 1; background: none;">&#9664;</button>

    <div id="cardContent" style="display: flex; gap: 20px; overflow-x: auto; scroll-behavior: smooth; width: 100%;"></div>

    <button onclick="scrollNext()" style="font-size: 24px; color: #333; border: none; border-radius: 50%; width: 40px; height: 40px; cursor: pointer; display: flex; justify-content: center; align-items: center; z-index: 1; background: none;">&#9654;</button>
</div>

<script>
    async function fetchData() {
        try {
            const response = await fetch("https://api.roboblog.co/api/stripe/get-all-post?domain=${main_blog_data?.[0]?.blog_domain}");
            const data = await response.json();
            document.getElementById("hiddenData").textContent = JSON.stringify(data);

            if (data && data.data && data.data.length > 0) {
                const cardContent = document.getElementById("cardContent");
                cardContent.innerHTML = ""; 

                data.data[0].posts.forEach(post => {
                    const card = document.createElement("div");
                    card.style.cssText = "flex: 0 0 auto; width: 300px; border: 1px solid #ddd; border-radius: 8px; overflow: hidden; background-color: #fff; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);";

                    const link = document.createElement("a");
                    link.href = "#";
                    link.target = "_blank";
                    link.rel = "noopener noreferrer";
                    link.style.textDecoration = "none";
                    link.style.color = "inherit";

                    const imgContainer = document.createElement("div");
                    const img = document.createElement("img");
                    img.src = post.image_url || 'https://via.placeholder.com/300x180';
                    img.alt = post.title;
                    img.style.width = "100%";
                    img.style.height = "180px";
                    img.style.objectFit = "cover";
                    imgContainer.appendChild(img);

                    const contentContainer = document.createElement("div");
                    contentContainer.style.padding = "15px";

                    const title = document.createElement("h2");
                    title.style.fontSize = "18px";
                    title.style.margin = "0 0 10px";
                    title.textContent = post.title;

                    const description = document.createElement("p");
                    description.style.fontSize = "14px";
                    description.style.color = "#666";
                    description.textContent = post.content.substring(0, 100) + "...";

                    contentContainer.appendChild(title);
                    contentContainer.appendChild(description);
                    link.appendChild(imgContainer);
                    link.appendChild(contentContainer);
                    card.appendChild(link);
                    cardContent.appendChild(card);
                });
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    fetchData();
     const cardContent = document.getElementById("cardContent");
    let scrollInterval;

    function startAutoScroll() {
        scrollInterval = setInterval(() => {
            if (cardContent.scrollLeft + cardContent.offsetWidth >= cardContent.scrollWidth) {
                cardContent.scrollTo({
                    left: 0,
                    behavior: "smooth"
                });
            } else {
                cardContent.scrollBy({
                    left: 300,
                    behavior: "smooth"
                });
            }
        }, 3000); 
    }

    function stopAutoScroll() {
        clearInterval(scrollInterval);
    }

    function scrollPrev() {
        stopAutoScroll();
        cardContent.scrollBy({
            left: -300,
            behavior: "smooth"
        });
        startAutoScroll();
    }

    function scrollNext() {
        stopAutoScroll();
        cardContent.scrollBy({
            left: 300,
            behavior: "smooth"
        });
        startAutoScroll();
    }

   
    startAutoScroll();

    cardContent.addEventListener("mouseenter", stopAutoScroll);
    cardContent.addEventListener("mouseleave", startAutoScroll);

    function scrollPrev() {
        document.getElementById("cardContent").scrollBy({ left: -300, behavior: "smooth" });
    }

    function scrollNext() {
        document.getElementById("cardContent").scrollBy({ left: 300, behavior: "smooth" });
    }
</script>

`;




const htmlCodebtn = `
   <a href="#" target="_blank" style="padding: 20px;" onclick="redirectToBlog()">
    <div style="position: fixed; bottom: 35px; right: 20px; background-color: #007bff; color: #ffffff; padding: 10px 20px; border-radius: 25px; 
                box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px; font-family: Arial, sans-serif; font-size: 16px; cursor: pointer; text-align: center; 
                z-index: 1000;">
        Our Blog
    </div>
</a>

<script>
function redirectToBlog() {
    window.open("https://royalenfield.roboblog.co", "_blank");
}
</script>

`;


    return (
        <>
       
            <section className="dasboard-all pages mt-5">
                <div className="container-fluid">
                  
                    <div className='d-flex flex-column justify-content-center text-center'>
                        <h3>Add Blog to Your Website</h3>
                        <span className='text-dark'>Slider for Your Website</span>
                    </div>
                    <div className="slider-main-page-main-blog">

                        <button className="prev-main-page-main-blog" onClick={handleScrollPrev}>
                            &#9664;
                        </button>

                        <div className="card-content-main-blog" ref={cardContentRef}>
                            {main_blog_data?.[0]?.posts?.map((ele, idx) => (
                                console.log("eeee", ele),
                                <div className="card-main-page-main-blog" key={idx} >
                                    <a
                                        className="text-decoration-none"
                                        href={`/blog/${ele?.id}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <div className="card-img-main-page-main-blog">
                                            <img
                                                src={ele?.image_url}
                                                alt={ele?.title || "Default Alt Text"}
                                            />
                                        </div>
                                        <div className="card-text-main-page-main-blog">
                                            <h2>{ele?.title || "Default Title"}</h2>
                                            <p>
                                                {cleanContent(ele?.content?.slice(0, 100) || "Default description for the blog post.") + "..."}
                                            </p>

                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>

                        <button className="next-main-page-main-blog" onClick={handleScrollNext}>
                            &#9654;
                        </button>
                    </div>
                    <div style={copycode}><div
                        className="code-section"
                        style={{
                            marginTop: "40px",
                            padding: "20px",
                            border: "1px solid #ddd",
                            borderRadius: "8px",
                            backgroundColor: "#f9f9f9",
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            width: "100%",
                            maxWidth: "55%"
                        }}
                    >
                        <h3>HTML Code for Slider</h3>
                        <pre
                            style={{
                                backgroundColor: "#f4f4f4",
                                padding: "15px",
                                borderRadius: "5px",
                                overflow: "auto",
                                height: "200px"

                            }}
                        >
                            {htmlCode}
                        </pre>

                        <div >
                            <button
                                onClick={() => copyToClipboard(htmlCode)}
                                style={{
                                    backgroundColor: "#007bff",
                                    color: "#fff",
                                    border: "none",
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                    marginRight: "10px",
                                }}
                            >
                                Copy HTML Code
                            </button>
                        </div>

                    </div></div>
                </div>
            </section>
            <section className="dasboard-all pages mt-5">
                <div className="container-fluid mt-5">

                    <div className='flt-btn-div' style={{ margin: " 110px 0px 0px 0px " }}>
                        <h3 className='d-flex justify-content-center'>Customize Button</h3>

                        <div className='d-flex flex-column justify-content-center'>
                            <div className='fom-div'>
                                <div className='mb-2'>
                                    <label className='px-3'>
                                        Button Text:
                                        <input
                                            type="text"
                                            value={text}
                                            onChange={(e) => setText(e.target.value)}
                                        />
                                    </label>
                                    <label className='px-3'>
                                        Background Color:
                                        <input
                                            type="color"
                                            value={bg_color}
                                            onChange={(e) => setbg_color(e.target.value)}
                                        />
                                    </label>
                                    <label className='px-3'>
                                        Text Color:
                                        <input
                                            type="color"
                                            value={text_color}
                                            onChange={(e) => settext_color(e.target.value)}
                                        />
                                    </label>
                                    <label className='px-3'>
                                        Font Size:
                                        <input
                                            type="text"
                                            value={fontSize}
                                            onChange={(e) => setfontSize(e.target.value)}
                                            placeholder="e.g., 16px"
                                        />
                                    </label>
                                </div>

                                <div className='d-flex justify-content-center'>
                                    <button className='d-flex justify-content-center rounded-pill'
                                        style={{
                                            backgroundColor: bg_color, color: text_color, fontSize: fontSize,

                                        }}
                                        disabled
                                    >
                                        {text}
                                    </button>
                                </div>
                            </div>

                        </div>
                        <div style={copycode}><div
                            className="code-section"
                            style={{
                                marginTop: "40px",
                                padding: "20px",
                                border: "1px solid #ddd",
                                borderRadius: "8px",
                                backgroundColor: "#f9f9f9",
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                width: "100%",
                                maxWidth: "55%"
                            }}
                        >
                            <h3>HTML Code for Floating Button</h3>
                            <pre
                                style={{
                                    backgroundColor: "#f4f4f4",
                                    padding: "15px",
                                    borderRadius: "5px",
                                    overflow: "auto",
                                }}
                            >
                                {htmlCodebtn}
                            </pre>

                            <div>
                                <button
                                    onClick={() => copyToClipboard(htmlCodebtn)}
                                    style={{
                                        backgroundColor: "#007bff",
                                        color: "#fff",
                                        border: "none",
                                        padding: "5px 10px",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                        marginRight: "10px",
                                    }}
                                >
                                    Copy HTML Code
                                </button>
                            </div>

                        </div></div>
                    </div>
                </div>
            </section>

        </>
    )
}
const copycode = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
}


export default BlogWebsite