import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import blogs from '../../../images/blog-img1.png'
import { CalendarOutlined } from "@ant-design/icons";
import {
  Add_Ai_posting,
  Gen_Ai_Topic,
  Gen_Topic_content,
  get_ai_topic_data,
  get_schedule_date,
  Post_save,
  Save_Ai_Topic,
  Schedule_date,
} from "../../../redux/authslice";
// import DatePicker from "react-datepicker";
// import type { DatePickerProps } from 'antd';
import { DatePicker, Space } from 'antd';
import dayjs from "dayjs";
import { toast } from "react-toastify";


const AddBlog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showManualModal, setShowManualModal] = useState(false);
  const [showCsvModal, setShowCsvModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAiModal, setShowAiModal] = useState(false);
  const [showAiResultsModal, setShowAiResultsModal] = useState(false);
  const [manualTopic, setManualTopic] = useState("");
  const [aiPrompt, setAiPrompt] = useState("");
  const [num_topics, setNum_topics] = useState(1);
  const [aiResults, setAiResults] = useState([]);
  const [topics, setTopics] = useState([]);
  const { Topic_data, gen_Topic_data, Topic_content_data, schedule_data, isLoading } = useSelector((state) => state.counter);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [showtopic, setShowTopic] = useState("All");
  const [selectedPost, setSelectedPost] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);



  const openModal = (post) => {
    // debugger
    if (post) {
      const formData = new FormData()
      formData.append("unique_id", post.id)
      formData.append("topic", post.topic)
      dispatch(Gen_Topic_content(formData, dispatch))
    }
  };


  const ShowModal = (id) => {
    if (id) {
      setIsModalOpen(true);
      const filteredData = schedule_data?.data?.filter((ele) => ele?.unique_id == id);
      setSelectedPost(filteredData?.[0]);
      setSelectedDate(filteredData?.[0]?.schedule_date)
    }
  };

  useEffect(() => {
    if (Topic_content_data && Object.keys(Topic_content_data).length > 0) {
      setIsModalOpen(true);
      setSelectedPost(Topic_content_data);
    }
  }, [Topic_content_data]);


  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedPost(null);
    setIsEditing(false);
  };

  const Publish = (post) => {

    if (!selectedDate) {
      toast.error("Add Schedule Date")
    } else {
      const formData = new FormData()
      formData.append("unique_id", post.unique_id)
      formData.append("title", post.title)
      formData.append("img_url", post.image_url)
      formData.append("content", post.content)
      formData.append("schedule_date", selectedDate)
      dispatch(Post_save(formData, dispatch, setIsModalOpen))

      const fmdata = new FormData()
      fmdata.append("schedule_date", selectedDate)
      fmdata.append("unique_id", post.unique_id)
      dispatch(Schedule_date(fmdata, dispatch))
      setIsModalOpen(false)
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    // Save logic here
    setIsEditing(false);
  };

  const buttonStyle = {
    height: "40px",
    width: "140px",
    backgroundColor: "#007bf",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "14px",
  };
  const handleDateChange = (date, dateString) => {
    setSelectedDate(dateString);
  };


  const cleanContent = (content) => {
    return content?.replace(/<\/?[^>]+(>|$)/g, '');
  };


  const handleAddManualTopic = () => {
    if (manualTopic.trim()) {
      let formdata = new FormData();
      formdata.append("type", "manual");
      formdata.append("topic", manualTopic);
      dispatch(Add_Ai_posting(formdata, dispatch));
      setManualTopic("");
      setShowManualModal(false);
    }
  };

  const handleGenerateTopics = (data) => {
    if (num_topics < 1) {
      return;
    }
    localStorage.setItem("topic", aiPrompt);
    dispatch(
      Gen_Ai_Topic(
        { prompt: aiPrompt ? aiPrompt : data, num_topics: num_topics },
        setAiPrompt,
        dispatch
      )
    );
  };

  const handleSaveAiTopic = () => {
    let body = { type: "ai", topics: gen_Topic_data };
    dispatch(Save_Ai_Topic({ body, dispatch }));
    setShowAiResultsModal(false);
  };

  const handleRegenerateTopics = () => {
    const data = localStorage.getItem("topic");
    handleGenerateTopics(data);
  };

  const handleDeleteTopic = (id) => {
    setTopics(topics?.filter((topic) => topic?.id !== id));
  };

  const handleCsvUpload = (event) => {
    const file = event.target.files?.[0];
    if (!file) {
      alert("No file selected.");
      return;
    } else {
      let formdata = new FormData();
      formdata.append("type", "csv");
      formdata.append("file", file);
      dispatch(Add_Ai_posting(formdata, dispatch));
    }
    setShowCsvModal(false);
  };

  const extractTopics = (data) => {
    return data.flatMap((entry) =>
      entry.topics.map((topic) => ({
        id: entry.id,
        uploaded_at: entry.uploaded_at,
        topic,
      }))
    );
  };

  const filteredTopics = Array.isArray(topics)
    ? topics.filter(topic => topic?.topic?.toLowerCase().includes(searchTerm.toLowerCase()))
    : [];


  const indexOfLastTopic = currentPage * itemsPerPage;
  const indexOfFirstTopic = indexOfLastTopic - itemsPerPage;
  const currentTopics = filteredTopics?.slice(
    indexOfFirstTopic,
    indexOfLastTopic
  );
  const totalPages = Math.ceil(filteredTopics?.length / itemsPerPage);

  const pageRange = (totalPages) => {
    const rangeSize = 5;
    let start = Math.floor((currentPage - 1) / rangeSize) * rangeSize + 1;
    let end = start + rangeSize - 1;
    if (end > totalPages) end = totalPages;
    return { start, end };
  };

  const { start, end } = pageRange(totalPages);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const sliceContent = (content) => {
    const parts = content?.split('.');

    if (parts?.length > 5) {
      // Firs?t 3 parts joined back with dots, ensuring a dot at the end
      const firstPart = parts?.slice(0, 5).join('.') + '.';
      const remainingPart = parts?.slice(5).join('.').trim();
      return { firstPart, remainingPart };
    }
    return { firstPart: content, remainingPart: '' };
  };

  const { firstPart, remainingPart } = sliceContent(cleanContent(selectedPost?.content));
  const isScheduled = (id) => {
    return schedule_data?.data?.some(item => item.unique_id === id);
  };
  useEffect(() => {
    if (Topic_data) {
      setTopics(Topic_data)
      // const manualTopics = Topic_data.manual || [];
      // const csvTopics = Topic_data.csv ? Topic_data.csv.flat() : [];
      // const aiTopics = Topic_data.ai || [];

      // const allTopics = [...manualTopics, ...csvTopics, ...aiTopics];
      // let filteredTopics = [];

      // if (showtopic === "All") {
      //   filteredTopics = extractTopics(allTopics);
      // } else if (showtopic === "Manual") {
      //   filteredTopics = extractTopics(manualTopics);
      // } else if (showtopic === "Csv") {
      //   filteredTopics = extractTopics(csvTopics);
      // } else if (showtopic === "Ai") {
      //   filteredTopics = extractTopics(aiTopics);
      // }
      // setTopics(filteredTopics);
    }
  }, [Topic_data, showtopic]);

  useEffect(() => {
    if (
      gen_Topic_data?.length > 0 &&
      gen_Topic_data &&
      Array.isArray(gen_Topic_data)
    ) {
      const generatedTopics = gen_Topic_data?.map((ele) => ele);
      setShowAiModal(false);
      setShowAiResultsModal(true);
      setAiResults(generatedTopics);
    }
  }, [gen_Topic_data?.length > 0]);

  useEffect(() => {
    dispatch(get_ai_topic_data(dispatch));
    dispatch(get_schedule_date(dispatch))
  }, []);

  const handleChange = (e) => {
    let value = e.target.value;
    if (value === "") {
      setNum_topics(value);
      return;
    }
    let numValue = Number(value);
    if (numValue < 1) numValue = 1;
    if (numValue > 20) numValue = 20;
    setNum_topics(numValue);
  };


  const handleChangedate = () => (date, dateString) => {
    console.log("Selected Date:", dateString);
  }

  return (
    <section className="dasboard-all pages">
      <div className="container-fluid">
        <div className="row">
          <div className="container mt-4">
            <h3>Manage Topics</h3>
            <div className="mb-3">
              <button
                className="btn btn-primary me-2"
                onClick={() => setShowManualModal(true)}
              >
                Add Manually
              </button>
              <button
                className="btn btn-info me-2"
                onClick={() => setShowCsvModal(true)}
              >
                Add CSV File
              </button>
              <button
                className="btn btn-success"
                onClick={() => setShowAiModal(true)}
              >
                AI-Assisted Topic
              </button>
              <div className="d-flex align-items-center justify-content-end">
                <label className="">Show Topics:</label>
                <select
                  value={showtopic}
                  onChange={(e) => {
                    setShowTopic(e.target.value);
                  }}
                  className="border px-2 py-0 m-0 rounded"
                  style={{ height: '40px' }}
                >
                  <option value="All">All</option>
                  <option value="Manual">Manual Topic</option>
                  <option value="Csv">CSV Topic</option>
                  <option value="Ai">Ai Topic</option>
                </select>
              </div>
            </div>
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search Topics"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>

            <table className="table table-bordered mt-3">
              <thead style={{ backgroundColor: "#0054aa" }}>
                <tr>
                  <th>Topic</th>
                  <th>Date Added</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(currentTopics) && currentTopics.length > 0 ? (
                  currentTopics.map((topic, idx) => (
                    <tr key={`${topic.id}-${idx}`}>
                      <td>{topic.topic}</td>
                      <td>{new Date(topic.uploaded_at).toLocaleString()}</td>
                      <td>
                        {isScheduled(topic.id) ? (
                          <button
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              ShowModal(topic.id);

                            }}
                          >
                            Show
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              openModal(topic);
                            }}
                          >
                            Generate
                          </button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3">No topics available</td>
                  </tr>
                )}
              </tbody>
            </table>

            {/* Pagination Controls */}
            <div className="d-flex justify-content-between">
              <div className="pagination-container col-md-9">
                <button
                  className="btn btn-link"
                  disabled={currentPage === 1}
                  onClick={() => paginate(currentPage - 1)}
                  style={{ textDecoration: "none" }}
                >
                  Previous
                </button>

                {[...Array(end - start + 1)].map((_, index) => (
                  <button
                    key={index}
                    className={`btn btn-link ${currentPage === start + index ? "active" : ""
                      }`}
                    onClick={() => paginate(start + index)}
                    style={{ textDecoration: "none" }}
                  >
                    {start + index}
                  </button>
                ))}

                {end < totalPages && (
                  <>
                    <span>...</span>
                    <button
                      className="btn btn-link"
                      onClick={() => paginate(end + 1)}
                      style={{ textDecoration: "none" }}
                    >
                      {end + 1}
                    </button>
                  </>
                )}

                <button
                  className="btn btn-link"
                  disabled={currentPage === totalPages}
                  onClick={() => paginate(currentPage + 1)}
                  style={{ textDecoration: "none" }}
                >
                  Next
                </button>
              </div>
              <div className="d-flex align-items-center">
                <label className="">Items per page:</label>
                <select
                  value={itemsPerPage}
                  onChange={(e) => {
                    setItemsPerPage(Number(e.target.value));
                  }}
                  className="border px-2 py-0 m-0 rounded"
                  style={{ height: '40px' }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                </select>
              </div>
            </div>

            {/* Add Manually Modal */}
            {showManualModal && (
              <div className="modal fade show d-block" tabIndex="-1">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Add Topic Manually</h5>
                      <button
                        className="btn-close"
                        onClick={() => setShowManualModal(false)}
                      ></button>
                    </div>
                    <div className="modal-body">
                      <input
                        type="text"
                        className="form-control"
                        value={manualTopic}
                        onChange={(e) => setManualTopic(e.target.value)}
                        placeholder="Enter topic"
                      />
                    </div>
                    <div className="modal-footer">
                      <button
                        className="btn btn-primary"
                        onClick={handleAddManualTopic}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Add CSV File Modal */}
            {showCsvModal && (
              <div className="modal fade show d-block" tabIndex="-1">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Upload CSV File</h5>
                      <button
                        className="btn-close"
                        onClick={() => setShowCsvModal(false)}
                      ></button>
                    </div>
                    <div className="modal-body">
                      <input
                        type="file"
                        className="form-control"
                        accept=".csv"
                        onChange={handleCsvUpload}
                      />
                    </div>
                    <div className="modal-footer"></div>
                  </div>
                </div>
              </div>
            )}

            {/* AI-Assisted Topic Modal */}
            {showAiModal && (
              <div className="modal fade show d-block" tabIndex="-1">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        Generate AI-Assisted Topic
                      </h5>
                      <button
                        className="btn-close"
                        onClick={() => setShowAiModal(false)}
                      ></button>
                    </div>
                    <div className="modal-body">
                      <textarea
                        className="form-control"
                        rows="3"
                        value={aiPrompt}
                        onChange={(e) => setAiPrompt(e.target.value)}
                        placeholder="Enter prompt for AI"
                      ></textarea>
                      <label>No. of topics generate</label>
                      <input
                        type="number"
                        min={1}
                        max={20}
                        maxLength={"2"}
                        className="form-control"
                        value={num_topics}
                        onChange={(e) => handleChange(e)}
                        placeholder="No. of topics generating"
                      />
                    </div>
                    <div className="modal-footer">
                      <button
                        className="btn btn-primary"
                        onClick={handleGenerateTopics}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* AI Results Modal */}
            {showAiResultsModal && (
              <div className="modal fade show d-block" tabIndex="-1">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">AI-Generated Topics</h5>
                      <button
                        className="btn-close"
                        onClick={() => setShowAiResultsModal(false)}
                      ></button>
                    </div>
                    <div className="modal-body">
                      <ul className="list-group">
                        {aiResults.map((topic, index) => (
                          <li key={index} className="list-group-item">
                            {topic}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="modal-footer">
                      <button
                        className="btn btn-primary"
                        onClick={handleSaveAiTopic}
                      >
                        Save
                      </button>
                      <button
                        className="btn btn-warning"
                        onClick={handleRegenerateTopics}
                      >
                        Regenerate
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {isModalOpen && (
              <div style={modalStyle} className="popup-main">
                <div className="popup-main-1" style={modalContentStyle}>
                  <span style={closeButtonStyle} onClick={closeModal}>
                    ×
                  </span>
                  {isEditing ? (
                    <div className="edit-input">
                      <input
                        type="text"
                        value={selectedPost?.title || ""}
                        className="edit-inner-input"
                        onChange={(e) => setSelectedPost({ ...selectedPost, title: e.target.value })}
                      />
                      <textarea
                        value={selectedPost?.content || ""}
                        className="edit-textarea-input"
                        onChange={(e) => setSelectedPost({ ...selectedPost, content: e.target.value })}
                      />
                      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                        <button style={editButtonStyle} onClick={handleSave}>
                          Save
                        </button>
                        <button style={closeButtonStyleModal} onClick={closeModal}>
                          Close
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex flex-column align-items-center">
                      <h2 style={{ color: "#333" }}>{selectedPost?.title}</h2>

                      {/* First part before the third dot */}
                      <p style={{
                        color: "#555", width: "100%", maxWidth: "85%", fontFamily: 'Jost',
                        fontSize: "17px"
                      }}>
                        {cleanContent?.(firstPart)?.replace(/<\/?[^>]+(>|$)/g, '')}
                      </p>

                      <div style={{ maxWidth: "400px", margin: "auto" }}>
                        <img
                          src={selectedPost?.image_url}
                          alt={selectedPost?.title}
                          className="fixed-image"
                        />
                      </div>

                      {/* Second part after the third dot, if it exists */}
                      {remainingPart && (
                        <p style={{
                          color: "#555", width: "100%", maxWidth: "85%", fontFamily: 'Jost',
                          fontSize: "17px"
                        }}>
                          {cleanContent?.(remainingPart)?.replace(/<\/?[^>]+(>|$)/g, '')}
                        </p>
                      )}

                      <div style={{ display: "flex", alignItems: "center", width: "100%", maxWidth: "500px", gap: "10px" }}>

                        <span style={{ fontSize: "14px", whiteSpace: "nowrap" }}>Set Schedule Date:</span>
                        <Space direction="vertical" size={30}>
                          <DatePicker value={selectedDate ? dayjs(selectedDate) : null} onChange={handleDateChange} />
                        </Space>


                        <button style={buttonStyle} onClick={handleEdit}>Edit Post</button>
                        <button className='btn btn-success' onClick={() => Publish(selectedPost)}>Publish</button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

const modalStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.8)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const modalContentStyle = {
  backgroundColor: "white",
  padding: "20px",
  borderRadius: "8px",
  maxWidth: "500px",
  position: "relative",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  overflowY: "auto",
  maxHeight: "80vh",
};

const closeButtonStyle = {
  position: "absolute",
  top: "10px",
  right: "10px",
  fontSize: "24px",
  cursor: "pointer",
  color: "#333",
};

const editButtonStyle = {
  backgroundColor: "#344767",
  color: "white",
  border: "none",
  padding: "10px 20px",
  borderRadius: "5px",
  cursor: "pointer",
  fontSize: "16px",
};

const closeButtonStyleModal = {
  backgroundColor: "transparent",
  color: "#333",
  border: "1px solid #333",
  padding: "10px 20px",
  borderRadius: "5px",
  cursor: "pointer",
  fontSize: "16px",
};



export default AddBlog;
