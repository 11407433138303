import React, { useEffect, useRef } from "react";
import dotted from "./img/others/dotted-img.png";
import border from "./img/others/border-1.png";
import plan from "./img/icons/plan-icon-1.png";
import plan2 from "./img/icons/plan-icon-2.png";
import plan3 from "./img/icons/plan-icon-3.png";
import plan4 from "./img/icons/plan-icon-4.png";
import about from "./img/others/about-img.png";
import dotted2 from "./img/others/dotted-img2.png";
import benefit from "./img/icons/benefit-icon-1.png";
import border2 from "./img/others/border-2.png";
import border3 from "./img/others/border-3.png";
import seo from "./img/others/seo-value-bg.png";
import blog from "./img/others/blog-img1.png";
import blogicon from "./img/icons/blog-icon-1.png";
import benefit2 from "./img/icons/benefit-icon-2.png";
import benefit3 from "./img/icons/benefit-icon-3.png";
import benefit4 from "./img/icons/benefit-icon-4.png";
import benefit5 from "./img/icons/benefit-icon-5.png";
import benefit6 from "./img/icons/benefit-icon-6.png";
import benefit7 from "./img/icons/benefit-icon-7.png";
import benefit8 from "./img/icons/benefit-icon-8.png";
import blogicon2 from "./img/icons/blog-icon-2.png";
import blogicon3 from "./img/icons/blog-icon-3.png";
import blogicon4 from "./img/icons/blog-icon-4.png";
import dotted3 from "./img/others/dotted-img3.png";
import dotted4 from "./img/others/dotted-img4.png";
import { useDispatch, useSelector } from "react-redux";
import { getPriceList } from "../../redux/authslice";
import { useLocation, useNavigate } from "react-router-dom";

const Plan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { priceList } = useSelector((state) => state.counter);
  const listData = priceList?.map((item) => item);
  const planSectionRef = useRef(null);


  useEffect(() => {
    dispatch(getPriceList({ dispatch }));

    if (location.state?.scrollToPlan) {
      planSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [dispatch, location]);

  const handleSetPriceId = (data) => {
    if (data) {
      navigate("/signup", { state: { priceId: data } });
    }
  };

  return (
    <div className="holder">
      <div className="plan-section text-center" >
        <div className="dotted-img-top" ref={planSectionRef}>
          <img src={dotted} className="img-fluid" alt="dotted-img" />
        </div>
        <div className="container">
          <div className="plan-heading heading">
            <h3>OUR PLANS</h3>
            <img src={border} className="img-fluid bor-1" alt="border-img" />
          </div>
          <div className="plan-wrap">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="plan-content">
                  <div className="plan-icon">
                    <img src={plan} className="img-fluid" alt="plan-icon" />
                  </div>
                  <h4>
                    $0.
                    <sup />
                    <span>/mo</span>
                  </h4>
                  <h3>Free Plan – Basic </h3>
                  <h5>Basic Blog with Unlimited Manual Posting by User (No Al Auto-Posting) </h5>
                  <ul className="list-unstyled">
                    <li>
                      <a href="#" className="btn">
                        CREATE YOUR OWN BLOG &amp; POST YOUR OWN CONTENT
                      </a>
                    </li>
                    <li>
                      <a className="btn" onClick={() => handleSetPriceId(listData.find(el=>el?.name==='free')?.price_id)}>
                        sign up
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="plan-content">
                  <div className="plan-icon">
                    <img src={plan2} className="img-fluid" alt="plan-icon" />
                  </div>
                  <h4>
                    $9.<sup>99</sup>
                    <span>/mo</span>
                  </h4>
                  <h3>Base Plan</h3>
                  <h5>
                    1 Auto-Generated Blog Post <b>PER MONTH</b>+Unlimited Manual Posting by User{" "}
                  </h5>
                  <ul className="list-unstyled">
                    <li>
                      <a href="#" className="btn">
                        EACH POST WILL BE UP TO 1,000 WORDS OF SEO-OPTIMIZED CONTENT!{" "}
                      </a>
                    </li>
                    <li>
                      <a className="btn" onClick={() => handleSetPriceId(listData.find(el=>el?.name==='base')?.price_id)}>
                        sign up
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="plan-content">
                  <div className="plan-icon">
                    <img src={plan3} className="img-fluid" alt="plan-icon" />
                  </div>
                  <h4>
                    $14.<sup>99</sup>
                    <span>/mo</span>
                  </h4>
                  <h3>Premium Plan</h3>
                  <h5>
                    1 Auto-Generated Blog Post <b>PER WEEK</b>+ Unlimited Manual Posting by User{" "}
                  </h5>
                  <ul className="list-unstyled">
                    <li>
                      <a href="#" className="btn">
                        {" "}
                        EACH POST WILL BE UP TO 2,000 WORDS OF SEO-OPTIMIZED CONTENT!{" "}
                      </a>
                    </li>
                    <li>
                      <a className="btn" onClick={() => handleSetPriceId(listData.find(el=>el?.name==='premium')?.price_id)}>
                        sign up
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="plan-content">
                  <div className="plan-icon">
                    <img src={plan4} className="img-fluid" alt="plan-icon" />
                  </div>
                  <h4>
                    $24.<sup>99</sup>
                    <span>/mo</span>
                  </h4>
                  <h3> Ultimate Plan</h3>
                  <h5>
                    1 Auto-Generated Blog Post <b>EVERY DAY</b>+Unlimited Manual Posting by User{" "}
                  </h5>
                  <ul className="list-unstyled">
                    <li>
                      <a href="#" className="btn">
                        EACH POST WILL BE UP TO 3,000 WORDS OF SEO-OPTIMIZED CONTENT!{" "}
                      </a>
                    </li>
                    <li>
                      <a className="btn" onClick={() => handleSetPriceId(listData.find(el=>el?.name==='ultimate')?.price_id)}>
                        sign up
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dotted-img-bottom">
          <img src={dotted} className="img-fluid" alt="dotted-img" />
        </div>
      </div>
      <div className="about-section">
        <div className="container-fuild">
          <div className="about-wrap">
            <div className="row">
              <div className="col-lg-6 d-none d-lg-block d-md-block d-none d-sm-none col-md-12 col-sm-12 col-12">
                <div className="about-img text-center">
                  <img src={about} className="img-fluid" width="100%" alt="about-img" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="about-contents heading">
                  <h3>about the platform</h3>
                  <img src={border} className="img-fluid bor-1" alt="border-img" />
                  <div className="d-lg-block d-md-none d-block d-sm-block">
                    <div className="about-img text-center">
                      <img
                        src={about}
                        className="img-fluid d-lg-none d-md-none d-block d-sm-block"
                        width="100%"
                        alt="about-img"
                      />
                    </div>
                  </div>
                  <p>
                    Each user will have a dashboard where they control their settings such as account information, billing, view
                    blog posts, view blog post topics, etc. From this dashboard, the user can customize the "look and feel" of
                    their blog including theme, hero banner, header, footer, logo, color scheme, etc. Once the blog is created,
                    you can launch it as a standalone blog or integrate it into an existing website.
                  </p>
                  <p>
                    The platform will automatically generate a list of blog topics based on your blog’s niche (or input topics of
                    your choice.) On regularly scheduled intervals (daily, weekly, or monthly based on your subscription), the
                    platform will generate SEO-Optimized articles, up to 3,000 words in length, and post them to your blog with
                    relevant images, links, and more!
                  </p>
                  <a href="#" className="">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="benefits-section text-center">
        <div className="container">
          <div className="dotted-img-top">
            <img src={dotted2} className="img-fluid" alt="dotted-img" />
          </div>
          <div className="benefits-heading heading">
            <h3>Benefits</h3>
            <img src={border} className="img-fluid bor-1" alt="border-img" />
          </div>
          <div className="benefits-wrap heading">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="benefits-contents">
                  <img src={benefit} className="img-fluid" alt="benefits-icons" />
                  <h4>Boost Your SEO Rankings</h4>
                  <img src={border2} className="img-fluid" alt="border-img" />
                  <p>Consistent blogging is one of the most effective ways to improve your website's search engine ranking.</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="benefits-contents">
                  <img src={benefit2} className="img-fluid" alt="benefits-icons" />
                  <h4>Build Authority and Trust</h4>
                  <img src={border2} className="img-fluid" alt="border-img" />
                  <p>
                    When you consistently share valuable insights, tips, and information on your blog, you establish yourself as
                    an authority in your industry.{" "}
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="benefits-contents">
                  <img src={benefit3} className="img-fluid" alt="benefits-icons" />
                  <h4>Drive More Traffic to Your Website</h4>
                  <img src={border2} className="img-fluid" alt="border-img" />
                  <p>
                    Each new blog post is a new page on your website, and each page is another opportunity to attract visitors.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="benefits-contents">
                  <img src={benefit4} className="img-fluid" alt="benefits-icons" />
                  <h4>Generate Leads and Nurture Relationships</h4>
                  <img src={border2} className="img-fluid" alt="border-img" />
                  <p>Blogging allows you to provide content that resonates with your target audience, helping you to capture</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="benefits-contents">
                  <img src={benefit5} className="img-fluid" alt="benefits-icons" />
                  <h4> Enhance Your Brand Voice</h4>
                  <img src={border2} className="img-fluid" alt="border-img" />
                  <p>Consistent blogging is one of the most effective ways to improve your website's search engine ranking.</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="benefits-contents">
                  <img src={benefit6} className="img-fluid" alt="benefits-icons" />
                  <h4>Support Your Social Media Strategy</h4>
                  <img src={border2} className="img-fluid" alt="border-img" />
                  <p>
                    When you consistently share valuable insights, tips, and information on your blog, you establish yourself as
                    an authority in your industry.{" "}
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="benefits-contents">
                  <img src={benefit7} className="img-fluid" alt="benefits-icons" />
                  <h4>Educate and Engage Your Audience</h4>
                  <img src={border2} className="img-fluid" alt="border-img" />
                  <p>
                    Each new blog post is a new page on your website, and each page is another opportunity to attract visitors.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="benefits-contents">
                  <img src={benefit8} className="img-fluid" alt="benefits-icons" />
                  <h4>Achieve Long-Term Results</h4>
                  <img src={border2} className="img-fluid" alt="border-img" />
                  <p>Blogging allows you to provide content that resonates with your target audience, helping you to capture</p>
                </div>
              </div>
            </div>
            <div className="benefits-btn">
              <a href="/signup" className="btn">
                SIGN UP NOW FOR FREE
              </a>
            </div>
          </div>
          <div className="dotted-img-bottom">
            <img src={dotted2} className="img-fluid" alt="dotted-img" />
          </div>
        </div>
      </div>
      <div className="seo-value-section">
        <div className="container">
          <div className="row">
            <div className="col-md-7"></div>
            <div className="col-md-5">
              <div className="seo-value-contents heading">
                <h3>SEO value</h3>
                <img src={border} className="img-fluid bor-1" alt="border-img" />
                <img src={seo} width="100%" className="img-fluid d-md-none d-lg-none d-block d-sm-block" alt="seo-value-img" />
                <p>
                  Regularly posting unique content on a blog offers significant SEO benefits. Search engines, like Google,
                  prioritize websites that consistently provide fresh, relevant content, resulting in higher search rankings. This
                  increased visibility can lead to more organic traffic and improve a site's authority. Unique content also helps
                  engage users and encourage backlinks, which further boosts SEO performance. Automating this process through
                  SiteBlog not only maintains these SEO benefits but also saves users valuable time and effort. Instead of
                  manually researching, writing, and scheduling posts, users can rely on AI to generate high-quality content
                  regularly. This ensures a steady flow of updates, keeping the site optimized without the usual workload,
                  allowing users to focus on other important tasks
                </p>
                <a href="#" className="btn">
                  Read more
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-section-land">
        <div className="container">
          <div className="blog-wrap">
            <div className="row">
              <div className="col-lg-6 col-md-12 d-block d-md-block d-lg-none d-sm-block">
                <div className="blog-img-land heading">
                  <h3>
                    How to Set Up <br />
                    the Auto-Blog
                  </h3>
                  <img src={border} className="img-fluid bor-1" alt="border-img" />
                  <img src={blog} className="img-fluid" alt="blog-img" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="blog-left-contents heading">
                  <h3 className="d-none d-md-none d-lg-block d-sm-none">
                    How to Set Up <br />
                    the Auto-Blog
                  </h3>
                  <img src={border} className="img-fluid bor-1 d-none d-md-none d-lg-block d-sm-none" alt="border-img" />
                  <div className="blog-wrap-2">
                    <div className="blog-icons">
                      <div className="row">
                        <div className="col-lg-2 col-md-4">
                          <div className="left-icon text-center">
                            <img src={blogicon} className="img-fluid" alt="blog-icons" />
                            <img src={border3} className="img-fluid b-2" alt="border-img" />
                          </div>
                        </div>
                        <div className="col-lg-10 col-md-8">
                          <div className="right-cont">
                            <h4>Step 1</h4>
                            <p>Select frequency of Automated Blog Posts (daily, weekly, or monthly)</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="blog-icons">
                      <div className="row">
                        <div className="col-lg-2 col-md-4">
                          <div className="left-icon text-center">
                            <img src={blogicon2} className="img-fluid" alt="blog-icons" />
                            <img src={border3} className="img-fluid b-2" alt="border-img" />
                          </div>
                        </div>
                        <div className="col-lg-10 col-md-8">
                          <div className="right-cont">
                            <h4>Step 2</h4>
                            <p>Name your blog</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="blog-icons">
                      <div className="row">
                        <div className="col-lg-2 col-md-4">
                          <div className="left-icon text-center">
                            <img src={blogicon3} className="img-fluid" alt="blog-icons" />
                            <img src={border3} className="img-fluid b-2" alt="border-img" />
                          </div>
                        </div>
                        <div className="col-lg-10 col-md-8">
                          <div className="right-cont">
                            <h4>Step 3</h4>
                            <p>Select your blog topics (or let AI choose them for you)</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="blog-icons">
                      <div className="row">
                        <div className="col-lg-2 col-md-4">
                          <div className="left-icon text-center">
                            <img src={blogicon4} className="img-fluid" alt="blog-icons" />
                            <img
                              src={border3}
                              className="img-fluid b-2 d-block d-sm-block d-lg-none d-md-none"
                              alt="border-img"
                            />
                          </div>
                        </div>
                        <div className="col-lg-10 col-md-8">
                          <div className="right-cont">
                            <h4>Step 4</h4>
                            <p>Go do something else and let AI do all your blogging for you! </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 d-none d-md-none d-lg-block d-sm-none">
                <div className="blog-img">
                  <img src={blog} className="img-fluid" alt="blog-img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="create-section text-center heading">
        <div className="dotted-img-top">
          <img src={dotted3} className="img-fluid" alt="dotted-img" />
        </div>
        <div className="container">
          <div className="create-wrap">
            <h3>Create your free online blog</h3>
            <h4>No fees. No catch. In just a few clicks.</h4>
            <a href="/signup" className="btn">
              SIGN UP NOW FOR FREE
            </a>
          </div>
        </div>
        <div className="dotted-img-bottom">
          <img src={dotted4} className="img-fluid" alt="dotted-img" />
        </div>
      </div>
    </div>
  );
};

export default Plan;
